/*
 * @Author: Summer.Chen
 * @Date:   2022-11-08 13:09:34
 * @Last Modified by:   Summer.Chen
 * @Last Modified time: 2022-11-16 13:24:33
 */
import en_oneClickUpgradeManagement from './en-US/translation.json';
import zh_oneClickUpgradeManagement from './zh-CN/translation.json';
import ja_oneClickUpgradeManagement from './ja-JP/translation.json';
import de_oneClickUpgradeManagement from './de-DE/translation.json';
import nl_oneClickUpgradeManagement from './nl-NL/translation.json';
import it_oneClickUpgradeManagement from './it-IT/translation.json';
import sp_oneClickUpgradeManagement from './sp-SP/translation.json';
import el_oneClickUpgradeManagement from './el-GR/translation.json';
import fr_oneClickUpgradeManagement from './fr-FR/translation.json';

export const oneClickUpgradeManagement = {
  en_oneClickUpgradeManagement,
  zh_oneClickUpgradeManagement,
  ja_oneClickUpgradeManagement,
  de_oneClickUpgradeManagement,
  nl_oneClickUpgradeManagement,
  it_oneClickUpgradeManagement,
  sp_oneClickUpgradeManagement,
  el_oneClickUpgradeManagement,
  fr_oneClickUpgradeManagement,
};
