/*
 * @Author: Summer.Chen
 * @Date:   2022-11-08 13:09:34
 * @Last Modified by:   Summer.Chen
 * @Last Modified time: 2022-11-16 13:24:33
 */
import en_menuPermissions from './en-US/translation.json';
import zh_menuPermissions from './zh-CN/translation.json';
import ja_menuPermissions from './ja-JP/translation.json';
import de_menuPermissions from './de-DE/translation.json';
import nl_menuPermissions from './nl-NL/translation.json';
import it_menuPermissions from './it-IT/translation.json';
import sp_menuPermissions from './sp-SP/translation.json';
import el_menuPermissions from './el-GR/translation.json';
import fr_menuPermissions from './fr-FR/translation.json';

export const menuPermissions = {
  en_menuPermissions,
  zh_menuPermissions,
  ja_menuPermissions,
  de_menuPermissions,
  nl_menuPermissions,
  it_menuPermissions,
  sp_menuPermissions,
  el_menuPermissions,
  fr_menuPermissions,
};
