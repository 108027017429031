/*
 * @Description:
 * @Author: Wayne Hu
 * @LastEditors: Please set LastEditors
 * @Date: 2023/07/18 15:03:13
 * @LastEditTime: 2024/11/09
 */
import { langCommon } from './locales/common'; //公共需要翻译的内容
import { langcliFeedback } from './locales/clientFeedback'; // 授权模块翻译
import { communicationDataManage } from './locales/CommunicationDataManagement'; // 授权模块翻译
import { oneClickUpgradeManagement } from './locales/oneClickUpgradeManagement'; // 授权模块翻译
import { qualityAssuranceManagement } from './locales/qualityAssuranceManagement'; // 授权模块翻译
import { otherInformation } from './locales/otherInformation'; // 更新日志管理
import { informationCenter } from './locales/informationCenter'; // 其他信息
import { langAboAlpCloud } from './locales/aboutAlphaCloud'; // 其他信息
import { energyStorageSys } from './locales/energyStorageSys';
import { energyStorageSystemMaintenance } from './locales/energyStorageSystemMaintenance';
import { reportManagement } from './locales/reportManagement';
import { basicSettings } from './locales/basicSettings';
import { langLogin } from './locales/login'; //登录
import { productModelConfigurationManagement } from './locales/productModelConfigurationManagement'; //登录
import { energyStorageList } from './locales/energyStorageList'; // 更新日志管理
import { langIndex } from './locales/index'; //首页
import { langLayout } from './locales/layout';
import { batteryExpansionDataLang } from './locales/energyStorage/batteryExpansionDataLocal'; //首页
import { energyStorageLang } from './locales/energyStorage/local';
import { menuPermissions } from './locales/menuPermissions';
import { aftersalesManagement } from './locales/aftersalesTranslation';
import { authorityManagement } from './locales/authorityManagement';
import { langMaintenanceManagement } from './locales/maintenanceManagement';

// 把所有的翻译资源集合
const resources = {
  en: {
    translation: {
      ...langCommon.en_common,
      ...langcliFeedback.en_clientFeedback,
      ...communicationDataManage.en_communicationDataManage,
      ...oneClickUpgradeManagement.en_oneClickUpgradeManagement,
      ...qualityAssuranceManagement.en_qualityAssuranceManagement,
      ...otherInformation.en_otherInformation,
      ...informationCenter.en_informationCenter,
      ...langAboAlpCloud.en_aboutAlphaCloud,
      ...energyStorageSys.en_energyStorageSys,
      ...energyStorageSystemMaintenance.en_energyStorageSystemMaintenance,
      ...reportManagement.en_reportManagement,
      ...basicSettings.en_basicSettings,
      ...langLogin.en_login,
      ...productModelConfigurationManagement.en_productModelConfigurationManagement,
      ...energyStorageList.en_energyStorageList,
      ...langIndex.en_index,
      ...langLayout.en_layout,
      ...batteryExpansionDataLang.en,
      ...energyStorageLang.en,
      ...menuPermissions.en_menuPermissions,
      ...aftersalesManagement.en_aftersalesManagement,
      ...authorityManagement.en_authorityManagement,
      ...langMaintenanceManagement.en_maintenanceManagement,
    },
  },
  zh: {
    translation: {
      ...langCommon.zh_common,
      ...langcliFeedback.zh_clientFeedback,
      ...communicationDataManage.zh_communicationDataManage,
      ...oneClickUpgradeManagement.zh_oneClickUpgradeManagement,
      ...qualityAssuranceManagement.zh_qualityAssuranceManagement,
      ...otherInformation.zh_otherInformation,
      ...informationCenter.zh_informationCenter,
      ...langAboAlpCloud.zh_aboutAlphaCloud,
      ...energyStorageSys.zh_energyStorageSys,
      ...energyStorageSystemMaintenance.zh_energyStorageSystemMaintenance,
      ...reportManagement.zh_reportManagement,
      ...basicSettings.zh_basicSettings,
      ...langLogin.zh_login,
      ...productModelConfigurationManagement.zh_productModelConfigurationManagement,
      ...energyStorageList.zh_energyStorageList,
      ...langIndex.zh_index,
      ...langLayout.zh_layout,
      ...batteryExpansionDataLang.zh,
      ...energyStorageLang.zh,
      ...menuPermissions.zh_menuPermissions,
      ...aftersalesManagement.zh_aftersalesManagement,
      ...authorityManagement.zh_authorityManagement,
      ...langMaintenanceManagement.zh_maintenanceManagement,
    },
  },
  de: {
    translation: {
      ...langCommon.de_common,
      ...langcliFeedback.de_clientFeedback,
      ...communicationDataManage.de_communicationDataManage,
      ...oneClickUpgradeManagement.de_oneClickUpgradeManagement,
      ...qualityAssuranceManagement.de_qualityAssuranceManagement,
      ...otherInformation.de_otherInformation,
      ...informationCenter.de_informationCenter,
      ...langAboAlpCloud.de_aboutAlphaCloud,
      ...energyStorageSys.de_energyStorageSys,
      ...energyStorageSystemMaintenance.de_energyStorageSystemMaintenance,
      ...reportManagement.de_reportManagement,
      ...basicSettings.de_basicSettings,
      ...langLogin.de_login,
      ...productModelConfigurationManagement.de_productModelConfigurationManagement,
      ...energyStorageList.de_energyStorageList,
      ...langIndex.de_index,
      ...langLayout.de_layout,
      ...batteryExpansionDataLang.de,
      ...energyStorageLang.de,
      ...menuPermissions.de_menuPermissions,
      ...aftersalesManagement.de_aftersalesManagement,
      ...authorityManagement.de_authorityManagement,
      ...langMaintenanceManagement.de_maintenanceManagement,
    },
  },
  ja: {
    translation: {
      ...langCommon.ja_common,
      ...langcliFeedback.ja_clientFeedback,
      ...communicationDataManage.ja_communicationDataManage,
      ...oneClickUpgradeManagement.ja_oneClickUpgradeManagement,
      ...qualityAssuranceManagement.ja_qualityAssuranceManagement,
      ...otherInformation.ja_otherInformation,
      ...informationCenter.ja_informationCenter,
      ...langAboAlpCloud.ja_aboutAlphaCloud,
      ...energyStorageSys.ja_energyStorageSys,
      ...energyStorageSystemMaintenance.ja_energyStorageSystemMaintenance,
      ...reportManagement.ja_reportManagement,
      ...basicSettings.ja_basicSettings,
      ...langLogin.ja_login,
      ...productModelConfigurationManagement.ja_productModelConfigurationManagement,
      ...energyStorageList.ja_energyStorageList,
      ...langIndex.ja_index,
      ...langLayout.ja_layout,
      ...batteryExpansionDataLang.ja,
      ...energyStorageLang.ja,
      ...menuPermissions.ja_menuPermissions,
      ...aftersalesManagement.ja_aftersalesManagement,
      ...authorityManagement.ja_authorityManagement,
      ...langMaintenanceManagement.ja_maintenanceManagement,
    },
  },
  it: {
    translation: {
      ...langCommon.it_common,
      ...langcliFeedback.it_clientFeedback,
      ...communicationDataManage.it_communicationDataManage,
      ...oneClickUpgradeManagement.it_oneClickUpgradeManagement,
      ...qualityAssuranceManagement.it_qualityAssuranceManagement,
      ...otherInformation.it_otherInformation,
      ...informationCenter.it_informationCenter,
      ...langAboAlpCloud.it_aboutAlphaCloud,
      ...energyStorageSys.it_energyStorageSys,
      ...energyStorageSystemMaintenance.it_energyStorageSystemMaintenance,
      ...reportManagement.it_reportManagement,
      ...basicSettings.it_basicSettings,
      ...langLogin.it_login,
      ...productModelConfigurationManagement.it_productModelConfigurationManagement,
      ...energyStorageList.it_energyStorageList,
      ...langIndex.it_index,
      ...langLayout.it_layout,
      ...batteryExpansionDataLang.it,
      ...energyStorageLang.it,
      ...menuPermissions.it_menuPermissions,
      ...aftersalesManagement.it_aftersalesManagement,
      ...authorityManagement.it_authorityManagement,
      ...langMaintenanceManagement.it_maintenanceManagement,
    },
  },
  nl: {
    translation: {
      ...langCommon.nl_common,
      ...langcliFeedback.nl_clientFeedback,
      ...communicationDataManage.nl_communicationDataManage,
      ...oneClickUpgradeManagement.nl_oneClickUpgradeManagement,
      ...qualityAssuranceManagement.nl_qualityAssuranceManagement,
      ...otherInformation.nl_otherInformation,
      ...informationCenter.nl_informationCenter,
      ...langAboAlpCloud.nl_aboutAlphaCloud,
      ...energyStorageSys.nl_energyStorageSys,
      ...energyStorageSystemMaintenance.nl_energyStorageSystemMaintenance,
      ...reportManagement.nl_reportManagement,
      ...basicSettings.nl_basicSettings,
      ...langLogin.nl_login,
      ...productModelConfigurationManagement.nl_productModelConfigurationManagement,
      ...energyStorageList.nl_energyStorageList,
      ...langIndex.nl_index,
      ...langLayout.nl_layout,
      ...batteryExpansionDataLang.nl,
      ...energyStorageLang.nl,
      ...menuPermissions.nl_menuPermissions,
      ...aftersalesManagement.nl_aftersalesManagement,
      ...authorityManagement.nl_authorityManagement,
      ...langMaintenanceManagement.nl_maintenanceManagement,
    },
  },
  sp: {
    translation: {
      ...langCommon.sp_common,
      ...langcliFeedback.sp_clientFeedback,
      ...communicationDataManage.sp_communicationDataManage,
      ...oneClickUpgradeManagement.sp_oneClickUpgradeManagement,
      ...qualityAssuranceManagement.sp_qualityAssuranceManagement,
      ...otherInformation.sp_otherInformation,
      ...informationCenter.sp_informationCenter,
      ...langAboAlpCloud.sp_aboutAlphaCloud,
      ...energyStorageSys.sp_energyStorageSys,
      ...energyStorageSystemMaintenance.sp_energyStorageSystemMaintenance,
      ...reportManagement.sp_reportManagement,
      ...basicSettings.sp_basicSettings,
      ...langLogin.sp_login,
      ...productModelConfigurationManagement.sp_productModelConfigurationManagement,
      ...energyStorageList.sp_energyStorageList,
      ...langIndex.sp_index,
      ...langLayout.sp_layout,
      ...batteryExpansionDataLang.sp,
      ...energyStorageLang.sp,
      ...menuPermissions.sp_menuPermissions,
      ...aftersalesManagement.sp_aftersalesManagement,
      ...authorityManagement.sp_authorityManagement,
      ...langMaintenanceManagement.sp_maintenanceManagement,
    },
  },
  el: {
    translation: {
      ...langCommon.el_common,
      ...langcliFeedback.el_clientFeedback,
      ...communicationDataManage.el_communicationDataManage,
      ...oneClickUpgradeManagement.el_oneClickUpgradeManagement,
      ...qualityAssuranceManagement.el_qualityAssuranceManagement,
      ...otherInformation.el_otherInformation,
      ...informationCenter.el_informationCenter,
      ...langAboAlpCloud.el_aboutAlphaCloud,
      ...energyStorageSys.el_energyStorageSys,
      ...energyStorageSystemMaintenance.el_energyStorageSystemMaintenance,
      ...reportManagement.el_reportManagement,
      ...basicSettings.el_basicSettings,
      ...langLogin.el_login,
      ...productModelConfigurationManagement.el_productModelConfigurationManagement,
      ...energyStorageList.el_energyStorageList,
      ...langIndex.el_index,
      ...langLayout.el_layout,
      ...batteryExpansionDataLang.el,
      ...energyStorageLang.el,
      ...menuPermissions.el_menuPermissions,
      ...aftersalesManagement.el_aftersalesManagement,
      ...authorityManagement.el_authorityManagement,
      ...langMaintenanceManagement.el_maintenanceManagement,
    },
  },
  fr: {
    translation: {
      ...langCommon.fr_common,
      ...langcliFeedback.fr_clientFeedback,
      ...communicationDataManage.fr_communicationDataManage,
      ...oneClickUpgradeManagement.fr_oneClickUpgradeManagement,
      ...qualityAssuranceManagement.fr_qualityAssuranceManagement,
      ...otherInformation.fr_otherInformation,
      ...informationCenter.fr_informationCenter,
      ...langAboAlpCloud.fr_aboutAlphaCloud,
      ...energyStorageSys.fr_energyStorageSys,
      ...energyStorageSystemMaintenance.fr_energyStorageSystemMaintenance,
      ...reportManagement.fr_reportManagement,
      ...basicSettings.fr_basicSettings,
      ...langLogin.fr_login,
      ...productModelConfigurationManagement.fr_productModelConfigurationManagement,
      ...energyStorageList.fr_energyStorageList,
      ...langIndex.fr_index,
      ...langLayout.fr_layout,
      ...batteryExpansionDataLang.fr,
      ...energyStorageLang.fr,
      ...menuPermissions.fr_menuPermissions,
      ...aftersalesManagement.fr_aftersalesManagement,
      ...authorityManagement.fr_authorityManagement,
      ...langMaintenanceManagement.fr_maintenanceManagement,
    },
  },
};
export { resources };
