/*
 * @Author: Summer.Chen
 * @Date:   2022-11-08 13:09:34
 * @Last Modified by:   Summer.Chen
 * @Last Modified time: 2022-11-16 13:24:33
 */
import en_maintenanceManagement from './en-US/translation.json';
import zh_maintenanceManagement from './zh-CN/translation.json';
import ja_maintenanceManagement from './ja-JP/translation.json';
import de_maintenanceManagement from './de-DE/translation.json';
import nl_maintenanceManagement from './nl-NL/translation.json';
import it_maintenanceManagement from './it-IT/translation.json';
import sp_maintenanceManagement from './sp-SP/translation.json';
import el_maintenanceManagement from './el-GR/translation.json';
import fr_maintenanceManagement from './fr-FR/translation.json';
export const langMaintenanceManagement = {
  en_maintenanceManagement,
  zh_maintenanceManagement,
  ja_maintenanceManagement,
  de_maintenanceManagement,
  nl_maintenanceManagement,
  it_maintenanceManagement,
  sp_maintenanceManagement,
  fr_maintenanceManagement,
  el_maintenanceManagement,
};
