/*
 * @Author: Summer.Chen
 * @Date:   2022-11-08 13:09:34
 * @Last Modified by:   Summer.Chen
 * @Last Modified time: 2022-11-16 13:24:33
 */
import en_reportManagement from './en-US/translation.json';
import zh_reportManagement from './zh-CN/translation.json';
import ja_reportManagement from './ja-JP/translation.json';
import de_reportManagement from './de-DE/translation.json';
import nl_reportManagement from './nl-NL/translation.json';
import it_reportManagement from './it-IT/translation.json';
import sp_reportManagement from './sp-SP/translation.json';
import el_reportManagement from './el-GR/translation.json';
import fr_reportManagement from './fr-FR/translation.json';
export const reportManagement = {
  en_reportManagement,
  zh_reportManagement,
  ja_reportManagement,
  de_reportManagement,
  nl_reportManagement,
  it_reportManagement,
  sp_reportManagement,
  el_reportManagement,
  fr_reportManagement,
};
