/*
 * @Description: 登录翻译
 * @Author: YuKi
 * @Date: 2023/03/01
 * @LastEditors: April
 * @LastEditTime: 2024-04-18 17:27:01
 */
import en_login from './en-US/translation.json';
import zh_login from './zh-CN/translation.json';
import ja_login from './ja-JP/translation.json';
import de_login from './de-DE/translation.json';
import nl_login from './nl-NL/translation.json';
import it_login from './it-IT/translation.json';
import sp_login from './sp-SP/translation.json';
import el_login from './el-GR/translation.json';
import fr_login from './fr-FR/translation.json';
export const langLogin = { en_login, zh_login, ja_login, de_login, nl_login, it_login, sp_login, el_login, fr_login };
