/*
 * @Description:
 * @Author: Victor
 * @Date: 2023/02/07
 * @LastEditors: April
 * @LastEditTime: 2024-04-18 17:28:32
 */
import en_otherInformation from './en-US/translation.json';
import zh_otherInformation from './zh-CN/translation.json';
import ja_otherInformation from './ja-JP/translation.json';
import de_otherInformation from './de-DE/translation.json';
import nl_otherInformation from './nl-NL/translation.json';
import it_otherInformation from './it-IT/translation.json';
import sp_otherInformation from './sp-SP/translation.json';
import el_otherInformation from './el-GR/translation.json';
import fr_otherInformation from './fr-FR/translation.json';

export const otherInformation = {
  en_otherInformation,
  zh_otherInformation,
  ja_otherInformation,
  de_otherInformation,
  nl_otherInformation,
  it_otherInformation,
  sp_otherInformation,
  el_otherInformation,
  fr_otherInformation,
};
