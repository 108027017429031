/*
 * @Description:
 * @Author: Victor
 * @Date: 2023-03-29 20:04:16
 * @LastEditors: Wayne Hu
 * @LastEditTime: 2024/05/10 10:20:37
 */
const zh_layout = require('./lang/zh-CN/translation.json');
const en_layout = require('./lang/en-US/translation.json');
const de_layout = require('./lang/de-DE/translation.json');
const ja_layout = require('./lang/ja-JP/translation.json');
const it_layout = require('./lang/it-IT/translation.json');
const nl_layout = require('./lang/nl-NL/translation.json');
const sp_layout = require('./lang/sp-SP/translation.json');
const el_layout = require('./lang/el-GR/translation.json');
const fr_layout = require('./lang/fr-FR/translation.json');
export const langLayout = {
  zh_layout,
  en_layout,
  de_layout,
  ja_layout,
  it_layout,
  nl_layout,
  sp_layout,
  el_layout,
  fr_layout,
};
