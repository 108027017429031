/*
 * @Author: Summer.Chen
 * @Date:   2022-11-08 13:09:34
 * @Last Modified by:   Summer.Chen
 * @Last Modified time: 2022-11-16 13:24:33
 */
import en_qualityAssuranceManagement from './en-US/translation.json';
import zh_qualityAssuranceManagement from './zh-CN/translation.json';
import ja_qualityAssuranceManagement from './ja-JP/translation.json';
import de_qualityAssuranceManagement from './de-DE/translation.json';
import nl_qualityAssuranceManagement from './nl-NL/translation.json';
import it_qualityAssuranceManagement from './it-IT/translation.json';
import sp_qualityAssuranceManagement from './sp-SP/translation.json';
import el_qualityAssuranceManagement from './el-GR/translation.json';
import fr_qualityAssuranceManagement from './fr-FR/translation.json';

export const qualityAssuranceManagement = {
  en_qualityAssuranceManagement,
  zh_qualityAssuranceManagement,
  ja_qualityAssuranceManagement,
  de_qualityAssuranceManagement,
  nl_qualityAssuranceManagement,
  it_qualityAssuranceManagement,
  sp_qualityAssuranceManagement,
  el_qualityAssuranceManagement,
  fr_qualityAssuranceManagement,
};
