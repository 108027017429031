/*
 * @Author: Summer.Chen
 * @Date:   2022-11-08 13:09:34
 * @Last Modified by:   Summer.Chen
 * @Last Modified time: 2022-11-16 13:24:33
 */
import en_productModelConfigurationManagement from './en-US/translation.json';
import zh_productModelConfigurationManagement from './zh-CN/translation.json';
import ja_productModelConfigurationManagement from './ja-JP/translation.json';
import de_productModelConfigurationManagement from './de-DE/translation.json';
import nl_productModelConfigurationManagement from './nl-NL/translation.json';
import it_productModelConfigurationManagement from './it-IT/translation.json';
import sp_productModelConfigurationManagement from './sp-SP/translation.json';
import el_productModelConfigurationManagement from './el-GR/translation.json';
import fr_productModelConfigurationManagement from './fr-FR/translation.json';

export const productModelConfigurationManagement = {
  en_productModelConfigurationManagement,
  zh_productModelConfigurationManagement,
  ja_productModelConfigurationManagement,
  de_productModelConfigurationManagement,
  nl_productModelConfigurationManagement,
  it_productModelConfigurationManagement,
  sp_productModelConfigurationManagement,
  el_productModelConfigurationManagement,
  fr_productModelConfigurationManagement,
};
